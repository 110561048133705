<template>
  <div>
    <v-row>
      <v-col cols="12">
        <LessonsTable :itemsPerPage="20" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LessonsTable from "@/packages/admin/components/lessons/LessonsTable";
export default {
  name: "lessons",
  components: { LessonsTable }
};
</script>

<style scoped>

</style>